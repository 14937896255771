import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`UNI:`}</strong>{` An ERC-20 token that designates the weight of a user`}{`’`}{`s voting rights. The more UNI a user has in their wallet, the more weight their delegation or vote on a proposal holds.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Delegation:`}</strong>{` UNI holders cannot vote or create proposals until they delegate their voting rights to an address. Delegation can be given to one address at a time, including the holder`}{`’`}{`s own address. Note that delegation does not lock tokens; it simply adds votes to the chosen delegation address.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Proposal:`}</strong>{` A proposal is executable code that modifies the governance contract or treasury and how they work. In order to create a proposal, a user must have at least 1% (10M UNI) of all UNI delegated to their address. Proposals are stored in the `}{`”`}{`proposals`}{`”`}{` mapping of the Governor smart contract. All proposals are subject to a 7-day voting period. If the proposer does not maintain their vote weight balance throughout the voting period, the proposal may be canceled by anyone.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Quorum:`}</strong>{` In order for a vote to pass, it must achieve quorum of 4% of all UNI (40M) voting in the affirmative. The purpose of the quorum is to ensure that the only measures that pass have adequate voter participation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Voting:`}</strong>{` Users can vote for or against single proposals once they have voting rights delegated to their address. Votes can be cast while a proposal is in the `}{`”`}{`Active`}{`”`}{` state. Votes can be submitted immediately using `}{`”`}{`castVote`}{`”`}{` or submitted later with `}{`”`}{`castVoteBySig`}{`”`}{` (For more info on castVoteBySig and offline signatures, see EIP-712). If the majority of votes (and a 4% quorum of UNI) vote for a proposal, the proposal may be queued in the Timelock.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Voting Period:`}</strong>{` Once a proposal has been put forward, Starswap community members will have a seven day period (the Voting Period) to cast their votes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Timelock:`}</strong>{` All governance and other administrative actions are required to sit in the Timelock for a minimum of 2 days, after which they can be implemented.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      